<div *ngIf="!isViewCheckout">

  <div class="flex flex-1 justify-end pt-3" *ngIf="showAddCards">
    <button class="relative font-Lato-Regular font-semibold bg-white border-2 border-[#FF6D03] text-[#FF6D03] rounded-full
              flex justify-center items-center h-[38px] w-full text-sm"
            matTooltip="{{'YOU_HAVE_REACHED_THE_MAXIMUM_NUMBER_OF_CARD_ALLOWED' | translate}}"
            [matTooltipDisabled]="isLoadingCards || remainingSlots > 0"
            [disabled]="isLoadingCards || remainingSlots === 0"
            (click)="addCard()"
            [ngClass]="{'opacity-50': isLoadingCards || remainingSlots === 0, 'hover:bg-orange-100': !isLoadingCards && remainingSlots > 0}">
      <fa-icon class="relative cursor-pointer flex justify-center items-center  bg-white
                text-[#FF6D03] text-sm mr-3" [icon]="faCirclePlus"></fa-icon>
      {{ 'ADD_CARD' | translate }}
    </button>
  </div>

  <p class="relative font-Lato-Regular leading-4 text-gray-#727272 mt-4 text-center">
    {{ 'SELECT_CARD' | translate }}
  </p>

  <div class="relative w-full bg-white flex justify-start items-start min-h-346px">
    <div
      class="relative w-full min-h-346px border-0 border-[#dfdfdf] pb-3 items-center flex flex-col">
      <p *ngIf="!isLoadingCards" class="relative font-Lato-Regular font-semibold text-17px mt-5 mb-3">
        <span *ngIf="!filterByCredits && !filterByDebits">{{ 'CREDIT_DEBIT_CARDS' | translate }}</span>
        <span *ngIf="filterByCredits">{{ 'CREDIT_CARDS' | translate }}</span>
        <span *ngIf="filterByDebits">{{ 'DEBIT_CARDS' | translate }}</span>
      </p>
      <p *ngIf="isLoadingCards"
         class="relative font-Lato-Regular font-semibold text-17px mt-5 mb-3 bg-gray-300 rounded h-6 w-36 animate-pulse">
      </p>

      <div *ngIf="!isLoadingCards">
        <div class="relative w-full flex flex-col justify-start items-start gap-5 ml-5 mt-[18px]"
             *ngIf="!creditCards.length">
          <div class="relative w-full">{{ 'NO_SAVED_CARDS' | translate }}</div>
          <div class="relative w-full" *ngIf="!showAddCards && showManageCardsLink">
            <p class="relative cursor-pointer font-Lato-Regular text-[#252525] flex justify-start items-center underline" (click)="goToProfile()">
              <fa-icon class="relative cursor-pointer flex justify-center items-center rounded-full
              text-[#18396C] mr-3" [icon]="faCreditCard"></fa-icon>
              {{ 'MANAGE_MY_CARDS' | translate }}
            </p>
          </div>
        </div>
        <div
          class="grid grid-cols-1 gap-3">
          <div [ngClass]="{'border-[#FF6D03] border-l-4px': card.id == selectedCard?.id}"
               class="flex flex-col cursor-pointer" *ngFor="let card of creditCards">
            <div
              [ngClass]="{'shadow-custom ml-0': card.id == selectedCard?.id, 'transform transition duration-200 hover:scale-105 ml-1': card.id != selectedCard?.id}"
              class="relative hover:shadow-custom w-[266px] min-h-[84px] border-b border-[#dfdfdf] pt-[14px] pb-[18px] h-full shadow-custom-class"
              (click)="setSelectedCard(card)">
              <p class="relative bg-[#FF6D03] text-white rounded-full font-Lato-Regular font-bold uppercase flex justify-center items-center
                tracking-0.59px text-[11px] leading-[13px] h-19px w-140px" *ngIf="false">
                <!--                                   *ngIf="card.default">-->
                {{ 'PREDETERMINED' | translate }}
              </p>

              <div class="relative flex justify-start items-start ml-5 mt-[18px]">
                <div class="relative credit-card w-[90px] h-[57px] rounded">
                  <img class="" src='../../../../assets/imgs/create-card/{{ card.scheme | uppercase }}.svg' alt="">
                </div>
                <div class="relative w-[135px] ml-[11px] pt-1">
                  <p class="relative font-Lato-Regular font-bold text-[#252525]
                        text-[15px] leading-[18px]">{{ card.scheme | uppercase | translate }}</p>
                  <p class="relative font-Lato-Regular text-[#252525]
                        text-xs" *ngIf="card.type === CardTypeEnum.CREDIT">{{ 'CREDIT_CARD_ENDING_IN' | translate }}
                    *****{{ card.lastFour }}</p>
                  <p class="relative font-Lato-Regular text-[#252525]
                        text-xs" *ngIf="card.type === CardTypeEnum.DEBIT">{{ 'DEBIT_CARD_ENDING_IN' | translate }}
                    *****{{ card.lastFour }}</p>
                </div>
              </div>

              <div class="flex flex-col mt-2" *ngIf="showCardDetails">
                <div
                  class="flex flex-row gap-2 items-center justify-center font-Lato-Regular text-[#18396C] text-[13px] leading-4">
                  <span class="relative underline cursor-pointer" (click)="editCard(card)">{{
                      'EDIT' |
                        translate
                    }}</span>
                  <div>|</div>
                  <span class="relative underline cursor-pointer" (click)="removeCard(card!.id)">{{
                      'DELETE' |
                        translate
                    }}</span>
                </div>
                <!--                  <div class="flex items-center justify-center font-Lato-Regular text-[#18396C] text-[13px] leading-4 mt-1">-->
                <!--                    <span class="relative underline cursor-pointer">{{'SAVE_AS_PREDETERMINED' | translate}}</span>-->
                <!--                  </div>-->
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- ------SKELETON----- -->
      <div
        class="grid grid-cols-1 gap-3"
        *ngIf="isLoadingCards">
        <div class="flex flex-col cursor-pointer" *ngFor="let card of [1,2]">
          <div
            class="relative hover:shadow-custom w-[266px] min-h-[84px] border-b border-[#dfdfdf] pt-[14px] pb-[18px] h-full shadow-custom-class bg-gray-300 animate-pulse">
            <div class="relative flex justify-start items-start ml-5 mt-[18px]">
              <div class="relative credit-card w-[90px] h-[57px] rounded bg-gray-300 animate-pulse"></div>
              <div class="relative w-[135px] ml-[11px] pt-1">
                <p
                  class="relative font-Lato-Regular font-bold text-[#252525] text-[15px] leading-[18px] bg-gray-300 h-6 w-10">
                </p>
                <p class="relative font-Lato-Regular text-[#252525] text-xs bg-gray-300 h-4 w-full"></p>
                <p class="relative font-Lato-Regular text-[#252525] text-xs bg-gray-300 h-4 w-full"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ------SKELETON----- -->
    </div>
  </div>
  <!--  </div>-->
  <!--</section>-->

</div>


<section *ngIf="isViewCheckout" class="relative w-auto h-auto flex flex-wrap items-start bg-gray-#f2f2f2 pb-7">
  <div [ngClass]="{'pt-3': !isViewRemittance}" class="relative overflow-hidden flex flex-col justify-between
      us:flex-1
      xl:w-auto">

    <!--    <app-bread-crumbs class="relative w-full" [data]="data"></app-bread-crumbs>-->

    <div class="flex flex-col flex-1" *ngIf="showAddCards && isViewRemittance">
      <div class="h-[38px] items-center flex font-Lato-Regular font-semibold">{{ 'PAYMENT' | translate }}</div>
      <div class="items-center flex font-Lato-Regular text-[#727272]">{{
          'SELECT_THE_CARD_YOU_WANT_TO_PAY_WITH_REMITTANCE' | translate
        }}
      </div>
      <div class="flex justify-end">
        <button class="relative font-Lato-Regular font-semibold bg-white border-2 border-[#FF6D03] text-[#FF6D03] rounded-full
              flex justify-center items-center h-[38px] px-6 text-sm"
                matTooltip="{{'YOU_HAVE_REACHED_THE_MAXIMUM_NUMBER_OF_CARD_ALLOWED' | translate}}"
                [matTooltipDisabled]="isLoadingCards || remainingSlots > 0"
                [disabled]="isLoadingCards || remainingSlots === 0" (click)="addCard()"
                [ngClass]="{'opacity-50': isLoadingCards || remainingSlots === 0, 'hover:bg-orange-100': !isLoadingCards && remainingSlots > 0}">
          <fa-icon class="relative cursor-pointer flex justify-center items-center  bg-white
                text-[#FF6D03] text-sm mr-3" [icon]="faCirclePlus"></fa-icon>
          {{ 'ADD_CARD' | translate }}
        </button>
      </div>
    </div>

    <div [ngClass]="{'border border-red-500': !selectedCard && formMarkAsTouched}"
         class="relative bg-white flex justify-start items-start mt-2 us:justify-center">
      <div
        class="relative min-h-[346px] border-0 border-[#dfdfdf] pb-3 items-center flex flex-col">
        <p *ngIf="!isLoadingCards" class="relative font-Lato-Regular font-semibold text-[17px] mt-5 mb-3">
          <span *ngIf="!filterByCredits && !filterByDebits">{{ 'CREDIT_DEBIT_CARDS' | translate }}</span>
          <span *ngIf="filterByCredits">{{ 'CREDIT_CARDS' | translate }}</span>
          <span *ngIf="filterByDebits">{{ 'DEBIT_CARDS' | translate }}</span>
        </p>
        <p *ngIf="isLoadingCards"
           class="relative font-Lato-Regular font-semibold text-[17px] mt-5 mb-3 bg-gray-300 rounded h-6 w-36 animate-pulse">
        </p>

        <div *ngIf="!isLoadingCards">
          <div class="relative w-full flex flex-col justify-start items-start gap-5 ml-5 mt-[[18px]]"
               *ngIf="!creditCards.length">
            <div class="relative w-full">{{ 'NO_SAVED_CARDS' | translate }}</div>
            <div class="relative w-full" *ngIf="!showAddCards && showManageCardsLink">
              <p class="relative cursor-pointer font-Lato-Regular text-[#252525] flex justify-start items-center underline" (click)="goToProfile()">
                <fa-icon class="relative cursor-pointer flex justify-center items-center rounded-full
              text-[#18396C] mr-3" [icon]="faCreditCard"></fa-icon>
                {{ 'MANAGE_MY_CARDS' | translate }}
              </p>
            </div>
          </div>
          <div class="grid us:grid-cols-1">
            <div [ngClass]="{'border-[#FF6D03] border-l-4px': card.id == selectedCard?.id}"
                 class="relative flex flex-col cursor-pointer" *ngFor="let card of creditCards">
              <fa-icon *ngIf="card.id == selectedCard?.id" class="absolute bg-white text-[#FF6D03] right-3 top-2"
                       [icon]="faCheckCircle"></fa-icon>
              <div
                [ngClass]="{'shadow-custom ml-0': card.id == selectedCard?.id, 'transform transition duration-200 hover:scale-105 ml-1': card.id != selectedCard?.id}"
                class="relative hover:shadow-custom w-[266px] min-h-[84px] border-b border-[#dfdfdf] pt-[14px] pb-[18px] h-full shadow-custom-class"
                (click)="setSelectedCard(card)">
                <p class="relative bg-[#FF6D03] text-white rounded-full font-Lato-Regular font-bold uppercase flex justify-center items-center
                tracking-0.59px text-[11px] leading-[13px] h-19px w-140px" *ngIf="false">
                  <!--                                   *ngIf="card.default">-->
                  {{ 'PREDETERMINED' | translate }}
                </p>

                <div class="relative flex justify-start items-start ml-5 mt-[18px]">
                  <div class="relative credit-card w-[90px] h-[57px] rounded">
                    <img class="" src='../../../../assets/imgs/create-card/{{ card.scheme | uppercase }}.svg' alt="">
                  </div>
                  <div class="relative w-[135px] ml-[11px] pt-1">
                    <p class="relative font-Lato-Regular font-bold text-[#252525]
                        text-[15px] leading-[18px]">{{ card.scheme | uppercase | translate }}</p>
                    <p class="relative font-Lato-Regular text-[#252525]
                        text-xs" *ngIf="card.type === CardTypeEnum.CREDIT">{{ 'CREDIT_CARD_ENDING_IN' | translate }}
                      *****{{ card.lastFour }}</p>
                    <p class="relative font-Lato-Regular text-[#252525]
                        text-xs" *ngIf="card.type === CardTypeEnum.DEBIT">{{ 'DEBIT_CARD_ENDING_IN' | translate }}
                      *****{{ card.lastFour }}</p>
                  </div>
                </div>

                <div class="flex flex-col mt-2" *ngIf="showCardDetails">
                  <div
                    class="flex flex-row gap-2 items-center justify-center font-Lato-Regular text-[#18396C] text-[13px] leading-4">
                    <span class="relative underline cursor-pointer" (click)="editCard(selectedCard)">{{
                        'EDIT' |
                          translate
                      }}</span>
                    <div>|</div>
                    <span class="relative underline cursor-pointer" (click)="removeCard(selectedCard!.id)">{{
                        'DELETE' |
                          translate
                      }}</span>
                  </div>
                  <!--                  <div class="flex items-center justify-center font-Lato-Regular text-[#18396C] text-[13px] leading-4 mt-1">-->
                  <!--                    <span class="relative underline cursor-pointer">{{'SAVE_AS_PREDETERMINED' | translate}}</span>-->
                  <!--                  </div>-->
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- ------SKELETON----- -->
        <div class="grid grid-cols-1 gap-3" *ngIf="isLoadingCards">
          <div class="flex flex-col cursor-pointer" *ngFor="let card of [1,2]">
            <div
              class="relative hover:shadow-custom w-[266px] min-h-[84px] border-b border-[#dfdfdf] pt-[14px] pb-[18px] h-full shadow-custom-class bg-gray-300 animate-pulse">
              <div class="relative flex justify-start items-start ml-5 mt-[18px]">
                <div class="relative credit-card w-[90px] h-[57px] rounded bg-gray-300 animate-pulse"></div>
                <div class="relative w-[135px] ml-[11px] pt-1">
                  <p
                    class="relative font-Lato-Regular font-bold text-[#252525] text-[15px] leading-[18px] bg-gray-300 h-6 w-10">
                  </p>
                  <p class="relative font-Lato-Regular text-[#252525] text-xs bg-gray-300 h-4 w-full"></p>
                  <p class="relative font-Lato-Regular text-[#252525] text-xs bg-gray-300 h-4 w-full"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ------SKELETON----- -->
      </div>

      <div class="justify-center hidden" *ngIf="!isLoadingCards && showCardDetails">
        <div class="realtive flex flex-col items-center pt-8" @fadeInAnimationPositional
             *ngIf="selectedCard && isTransition">
          <div class="relative credit-card rounded
            w-[196px] h-[124px]">
            <img class="absolute top-2 w-10 right-3"
                 src='../../../../assets/imgs/create-card/{{ selectedCard.scheme | uppercase }}.svg' alt="">
            <img class="absolute w-6 top-10 left-4" src='../../assets/imgs/create-card/plate.png' alt="">
            <div
              class="absolute text-white text-[11px] bottom-6 left-4 font-Lato-Regular">{{ selectedCard.firstName }} {{
                selectedCard.lastName
              }}
            </div>
            <div class="absolute text-white text-10px bottom-2 right-3 font-Lato-Regular">{{
                selectedCard.expirationMonth + '/' + selectedCard.expirationYear
              }}
            </div>
          </div>

          <div
            class="flex flex-row gap-2 items-center justify-center font-Lato-Regular text-[#18396C] text-[13px] leading-4">
            <span class="relative underline cursor-pointer font-Lato-Regular text-[#18396C] text-[13px] leading-4 mt-4"
                  (click)="editCard(selectedCard)">{{ 'EDIT' | translate }}</span>
            <div class="relative underline cursor-pointer font-Lato-Regular text-[#18396C] text-[13px] leading-4 mt-4">
              |
            </div>
            <span class="relative underline cursor-pointer font-Lato-Regular text-[#18396C] text-[13px] leading-4 mt-4"
                  (click)="removeCard(selectedCard!.id)">{{ 'DELETE' | translate }}</span>
          </div>
          <!--          <span class="relative underline cursor-pointer font-Lato-Regular text-[#18396C] text-[13px] leading-4 mt-4"-->
          <!--                (click)="removeCard(selectedCard!.id)">{{'DELETE' | translate}}</span>-->
        </div>
      </div>

      <div class="justify-center hidden" *ngIf="isLoadingCards && showCardDetails">
        <div class="realtive flex justify-start items-start pt-8">
          <div class="relative credit-card rounded w-[196px] h-[124px] bg-gray-300 animate-pulse"></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="formMarkAsTouched && !selectedCard" class="absolute bottom-0.5 text-sm text-red-500">
    <p>{{ 'SELECT_A_PAYMENT_CARD' | translate }}</p>
  </div>
</section>
